import React, { useState, useEffect } from "react";
import styled from "styled-components";

const placeHolder =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6zwAAAgcBApocMXEAAAAASUVORK5CYII=";

const Image = styled.img`
  display: block;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  // Add a smooth animation on loading
  @keyframes loaded {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes animatedBackground {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 1000px 0;
    }
  }

  &:not(.loaded) {
    width: 100%;
    background-image: linear-gradient(
      90deg,
      #e4e4e4 0%,
      #f1f1f1 40%,
      #ededed 60%,
      #e4e4e4 100%
    );
    background-position: 0px 0px;
    background-repeat: repeat;
    animation: animatedBackground 5s linear infinite;
  }
  // I use utilitary classes instead of props to avoid style regenerating
  &.loaded:not(.has-error) {
    animation: loaded 300ms ease-in-out;
  }
`;

const LazyImage = (props) => {
  const { src, alt, style, className } = props;
  const [imageSrc, setImageSrc] = useState(placeHolder);
  const [imageRef, setImageRef] = useState();
  const [state, setState] = useState("loading");

  const onLoad = (event) => {
    event.target.classList.add("loaded");
    setState("loaded");
  };

  const onError = (event) => {
    setState("404");
  };

  useEffect(() => {
    let observer;
    let didCancel = false;

    if (imageRef && imageSrc !== src) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImageSrc(src);
                observer.unobserve(imageRef);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: "75%",
          }
        );
        observer.observe(imageRef);
      } else {
        // Old browsers fallback
        setImageSrc(src);
      }
    }
    return () => {
      didCancel = true;
      // on component cleanup, we remove the listner
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef);
      }
    };
  }, [src, imageSrc, imageRef]);
  if (state === "loading" || state === "loaded")
    return (
      <Image
        className={className}
        ref={setImageRef}
        src={imageSrc}
        alt={alt}
        height={style.height}
        width={imageSrc === placeHolder ? "100%" : style.width}
        onLoad={onLoad}
        onError={onError}
      />
    );
  return props.error(state);
};

export default LazyImage;
