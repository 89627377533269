import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Icon from "@lectra/icon";
import AnimatedNumber from "animated-number-react";
import { useTranslation } from "react-i18next";

const getIconString = (request) => {
  if (request.sort === "asc") return "sort-ascending";
  else return "sort-descending";
};

function SortComponent(props) {
  const { t } = useTranslation();
  if (props.orderBy === "fileName") {
    return (
      <div
        className="filename"
        title={t("Sort by file name")}
        onClick={props.toggleOrderBy}
      >
        AZ
      </div>
    );
  }
  if (props.orderBy === "rank") {
    return (
      <Icon
        type="favorite"
        color="#999"
        size={12}
        onClick={props.toggleOrderBy}
        title={t("Sort by relevance")}
      />
    );
  }
  if (props.orderBy === "date") {
    return (
      <Icon
        type="horloge"
        color="#999"
        size={12}
        onClick={props.toggleOrderBy}
        title={t("Sort by date")}
      />
    );
  }
}

function SearchBar(props) {
  const [searchTermDisplayed, setSearchTermDisplayed] = useState(
    props.searchString || ""
  );
  useEffect(() => {
    setSearchTermDisplayed(props.searchString);
  }, [props.searchString]);
  const { t } = useTranslation();

  const { titleText, designPackCount, request } = props;
  return (
    <div className={props.className}>
      <div className="searchBar">
        <div className="searchHeader">{titleText}</div>
        <div className="search">
          <div className="left">
            {designPackCount && (
              <span className="value">
                <AnimatedNumber
                  duration={400}
                  formatValue={(value) => value.toFixed(0)}
                  value={designPackCount}
                ></AnimatedNumber>{" "}
                {t("ELEMENTS")}
              </span>
            )}
          </div>
          <div className={`mid ${props.loading ? "loading" : ""}`}>
            <div className="options">
              <SortComponent
                orderBy={request.orderBy}
                toggleOrderBy={props.toggleOrderBy}
              ></SortComponent>
              <Icon
                type={getIconString(request)}
                color="#999"
                size={12}
                title={t("Sort direction")}
                onClick={props.toggleSort}
              />
            </div>
            <Icon
              className={`iconSearch ${props.loading ? "loading" : ""}`}
              type={props.loading ? "in-process" : "search"}
              color={props.loading ? "#2980b9" : "#999"}
              size={16}
              onClick={() =>
                props.onSet({
                  searchString: searchTermDisplayed || "",
                  skip: 0,
                })
              }
            />
            <input
              value={searchTermDisplayed}
              onChange={(event) => setSearchTermDisplayed(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter")
                  props.onSet({
                    searchString: searchTermDisplayed || "",
                    skip: 0,
                  });
              }}
            />
            <Icon
              className={`${
                searchTermDisplayed.length === 0 ? "invisible " : ""
              }iconClose`}
              type="close"
              color="#999"
              size="10pt"
              onClick={() => setSearchTermDisplayed("")}
            />
          </div>
          <div className="right">
            {designPackCount && (
              <span className="value">
                <AnimatedNumber
                  duration={400}
                  formatValue={(value) => value.toFixed(0)}
                  value={designPackCount}
                ></AnimatedNumber>{" "}
                ELEMENTS
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const midSpaccing = 20;

export default styled(SearchBar)`
  .search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .mid {
      display: flex;
      flex-direction: row;
      max-width: 33%;
      min-width: 150pt;
      align-self: center;
      background-color: #fff;
      border: 1pt solid #ccc;
      min-height: 25pt;
      flex: 1 1 auto;
      @keyframes barloading {
        from {
          width: 0;
          max-width: calc(33% - 85px - 35px);
        }
        to {
          width: calc(33% - 85px - 35px);
          max-width: calc(33% - 85px - 35px);
          min-width: calc(150pt - 85px - 35px);
        }
      }
      &.loading:after {
        content: ""; /* This is necessary for the pseudo element to work. */
        position: absolute;
        bottom: 16px;
        height: 3px;
        width: 0;
        background: ${(props) => props.theme.app.secondary.color};
        display: inline-block; /* This will put the pseudo element on its own line. */
        margin-left: 85px;
        outline: 1px solid white; /* This creates the border. Replace black with whatever color you want. */
        animation-name: barloading;
        animation-duration: 2s;
        animation-iteration-count: infinite;
      }
    }
    .left,
    .right {
      align-self: flex-end;
      flex: 0 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 20px;
      white-space: nowrap;
      min-height: 1px;
      .value {
        font-size: 12px;
      }
    }
    .right {
      visibility: hidden;
      flex: 0 1000 auto;
    }
  }
  .options {
    align-self: center;
    display: flex;
    flex-direction: row;
    border-right: 1px solid #999;
    padding: 3px;
    & > div {
      margin: 3px;
      cursor: pointer;
      width: 16px;
      &:hover {
        color: ${(props) => props.theme.app.secondary.color}!important;
      }
    }

    .filename {
      color: #999;
      font-size: 12px;
      width: 16px;
      height: 12px;
      line-height: 12px;
      overflow: hidden;
      &:hover {
        color: ${(props) => props.theme.app.secondary.color}!important;
      }
    }
  }
  .iconSearch {
    align-self: center;
    padding: 0 6pt 0 6pt;
    cursor: pointer;
  }
  .iconClose {
    align-self: center;
    padding: 0 6pt 0 6pt;
    cursor: pointer;
  }
  .invisible {
    display: none;
  }
  input {
    font-size: 12pt;
    width: 100%;
    border: none;
    color: #999;
    min-width: 0;
    &:focus {
      outline: none;
    }
  }

  .searchBar {
    height: 80px;
    display: flex;
    flex-direction: column;

    color: ${(props) => props.theme.app.primary.oppositeColor};
    background-color: ${(props) => props.theme.app.primary.color};
    text-align: center;
    z-index: 10;
  }
  .searchHeader {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12pt;
    font-weight: 600;
    padding: 4pt 0 4pt 0;
  }

  .subBar {
    background: white;
    .linkSelection {
    }
    display: grid;
    grid-template-columns: 2fr ${(props) => props.theme.app.detailCardWidth}px;
    grid-template-rows: 1fr;
    grid-column-gap: ${midSpaccing}px;
    grid-row-gap: 0;
  }
`;
