import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./components/app";
import GlobalStyle from "./lib/components/app/global.style";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "styled-components";
import defaultTheme from "@lectra/themes";
import AuthenticationProvider from "./components/authentication.context";

const theme = {
  primary: { color: "#34495e", oppositeColor: "white" },
  secondary: { color: "#2980b9", oppositeColor: "white" },
  danger: { color: "#f31b44" },
  detailCardWidth: 360,
  resultImageHeight: 200,
  resultImageSpacing: 10,
  windowMode: true,
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeProvider theme={{ ...defaultTheme, app: theme }}>
    <AuthenticationProvider
      auth0={{
        authDomain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
        baseUrl: window.origin,
        callbackUrl: "/",
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
    >
      <App selectDesignPack={(d) => console.log(d)} />
      <GlobalStyle />
    </AuthenticationProvider>
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
