import { useRef } from "react";

const useGetScrollBarWidth = () => {
  const scrollRef = useRef(null);
  /* eslint-disable no-undef */
  if (!scrollRef.current) return { ref: scrollRef, scrollWidth: 0 };
  return {
    ref: scrollRef,
    scrollWidth:
      scrollRef.current.offsetWidth - scrollRef.current.clientWidth - 2,
  };
  /* eslint-enable no-undef */
};

export default useGetScrollBarWidth;