import React from "react";
import CardImage from "./cardImage";
import { formatDate, formatTime } from "../../utils/time";
import findBestImage from "../../utils/findBestImage.js";

export default function designPackCardImage({
  designpack,
  box,
  onClick,
  selected,
  loading,
}) {
  return (
    <CardImage
      key={designpack.documentId}
      onClick={onClick}
      uri={findBestImage(designpack.mainBoardImages, box)}
      title={designpack.metadata.fileName}
      box={box}
      selected={selected}
      loading={loading}
    >
      <div className="bottom">
        <div title={designpack.metadata.fileName}>
          {designpack.metadata.fileName}
        </div>
        <div title={designpack.metadata.author}>
          {designpack.metadata.author}
        </div>
        <div title={formatTime(designpack.metadata.storedDate)}>
          {formatDate(designpack.metadata.storedDate)}
        </div>
      </div>
    </CardImage>
  );
}
