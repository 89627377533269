import { useEffect, useState } from "react";
import  i18n from "i18next"

export const mainboardSymbol = Symbol("mainboard");

function NetworkException(response) {
  this.message = "Network error";
  this.response = response;
  // Use V8's native method if available, otherwise fallback
  if ("captureStackTrace" in Error)
    Error.captureStackTrace(this, NetworkException);
  else this.stack = new Error().stack;
}

export default function useDesignData(props) {
  const {
    accessToken,
    baseUrlApi,
    scrollRef,
    onSelect,
    orderBy = "rank",
    sort = "desc",
  } = props;
  const myScrollElement = scrollRef.current;
  const headers = new Headers({
    Authorization: "Bearer " + accessToken,
    "Content-Type": "application/json",
  });
  useEffect(() => {
    if(props.language!=="auto")
      i18n.changeLanguage(props.language);
  },[props.language]);

  const [designPackCount, setDesignPackCount] = useState(null);
  const [designPackList, setDesignPackList] = useState([]);
  const setDesignPack = (designPack) => {
    setDesignPackList((designPackList) => {
      const index = designPackList.findIndex(
        (dp) => dp.documentId === designPack.documentId
      );
      const result = [...designPackList];
      result[index] = designPack;
      return result;
    });
  };
  const [loadings, setLoaddings] = useState({
    search: 0,
    detail: {},
    delete: 0,
  });
  const [request, setRequest] = useState({
    searchString: props.searchTerm || "",
    skip: 0,
    limit: 50,
    orderBy,
    sort,
  });
  const [
    selectedDocumentIdOfDesignPack,
    setSelectedDocumentIdOfDesignPack,
  ] = useState(null);
  const [selectedViewports, setSelectedViewports] = useState([]);

  const [error, setError] = useState(null);

  const toggleSort = () => {
    setSelectedDocumentIdOfDesignPack(null);
    setRequest((prev) => ({
      ...prev,
      sort: prev.sort === "asc" ? "desc" : "asc",
      skip: 0,
    }));
  };

  const toggleOrderBy = () => {
    setSelectedDocumentIdOfDesignPack(null);
    setRequest((prev) => ({
      ...prev,
      orderBy: prev.orderBy === "date" ? "rank" : "date",
      skip: 0,
    }));
  };

  const toggleViewportSelection = (name) => {
    if (onSelect == null) return;
    const r = [...selectedViewports];
    if (r.indexOf(name) === -1) r.push(name);
    else r.splice(r.indexOf(name), 1);
    setSelectedViewports(r);
  };

  const selectDesignPack = (designPack) => {
    setSelectedDocumentIdOfDesignPack(designPack.documentId);
    setSelectedViewports([]);
  };

  const onCloseDetail = () => {
    setSelectedDocumentIdOfDesignPack(null);
  };

  useEffect(() => {
    if (myScrollElement) {
      const handleScrollEnd = () => {
        setRequest((prev) => ({
          searchString: prev.searchString,
          skip: prev.skip + 50,
          orderBy: prev.orderBy || "date",
          sort: prev.sort || "desc",
          limit: 50,
        }));
      };
      const handleScroll = () => {
        if (
          myScrollElement.scrollHeight >=
          myScrollElement.offsetHeight + myScrollElement.scrollTop + 1
        )
          return;
        handleScrollEnd();
      };

      myScrollElement.addEventListener("scroll", handleScroll, false);
      return () => {
        if (myScrollElement) {
          myScrollElement.removeEventListener("scroll", handleScroll, false);
        }
      };
    }
  }, [myScrollElement, setRequest]);

  const searchDesignPacks = (request, abortController) => {
    props.onSearch(request.searchString, request.orderBy, request.sort);
    if (request.skip === 0) setDesignPackList([]);
    setLoaddings((prev) => ({ ...prev, search: prev.search + 1 }));
    fetch(
      `${baseUrlApi}/api/designpack-searches/${request.searchString}?Skip=${
        request.skip
      }&Limit=50&orderBy=${
        request.searchString === "" ? "date" : request.orderBy
      }&sort=${request.sort}`,
      {
        method: "get",
        headers,
        signal: abortController.signal,
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new NetworkException(response);
        }
        return response.json();
      })
      .then((response) => {
        setDesignPackList((prev) => [...prev, ...response.matchingDesignPacks]); // potential issue with slow network. -> insert data at the right position, not at the end of the array
        setDesignPackCount(response.matchingDesignPacksCount);
      })
      .catch(setError)
      .finally(() => {
        setLoaddings((prev) => ({ ...prev, search: prev.search - 1 }));
      });
  };

  const getDesignPack = (designPack, abortController) => {
    if (designPack == null) return;
    setLoaddings((prev) => ({
      ...prev,
      detail: { ...prev.detail, [designPack.documentId]: true },
    }));
    fetch(`${baseUrlApi}/api/designpack-analytics/${designPack.documentId}`, {
      method: "get",
      headers,
      signal: abortController.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new NetworkException(response);
        }
        return response.json();
      })
      .then((response) => response.designPack)
      .then((designPack) => setDesignPack(designPack))
      .catch(setError)
      .finally(() => {
        setLoaddings((prev) => ({
          ...prev,
          detail: { ...prev.detail, [designPack.documentId]: false },
        }));
      });
  };

  const deleteDesignPack = (designPack) => {
    setLoaddings((prev) => ({ ...prev, delete: prev.delete + 1 }));
    fetch(
      `${baseUrlApi}/api/designpack-analytics/metadata/${designPack.metadata.ident}`,
      {
        method: "delete",
        headers,
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new NetworkException(response);
        }
        return response.body;
      })
      .then((_) => {
        setDesignPack({ ...designPack, status: "deleted" });
      })
      .catch(setError)
      .finally(() => {
        setLoaddings((prev) => ({ ...prev, delete: prev.delete - 1 }));
      });
  };

  const selection =
    designPackList.find(
      (d) => d.documentId === selectedDocumentIdOfDesignPack
    ) || null;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const abortController = new AbortController();
    searchDesignPacks(request, abortController);
    return () => abortController.abort();
  }, [request]); // depend only on request

  useEffect(() => {
    const abortController = new AbortController();
    getDesignPack(selection, abortController);
    return () => abortController.abort();
  }, [selectedDocumentIdOfDesignPack]); // depend only on selectedDocumentIdOfDesignPack
  /* eslint-enable react-hooks/exhaustive-deps */

  return {
    ...props,
    toggleViewportSelection,
    deleteDesignPack,
    searchDesignPacks: (request) => {
      setSelectedDocumentIdOfDesignPack(null);
      setRequest((prev) => ({ ...prev, ...request }));
    },
    onCloseDetail,
    selectDesignPack,
    toggleSort,
    toggleOrderBy,
    selection,
    request,
    designPackCount,
    designPackList,
    selectedViewports,
    loadings,
    error,
  };
}
