import React from "react";
import styled from "styled-components";
import Icon from "@lectra/icon";

function Error({ className, exception }) {
  if (exception.name === "AbortError") {
    // fetch has been aborted
    return null;
  }
  debugger;
  if (!exception) return null;
  let message = "Unexpected Error";
  if (exception.message === "Network error") {
    if (exception.response && exception.response.status) {
      message = exception.response.status;
    }
  }
  console.error(exception);
  /* eslint-disable no-self-assign */
  return (
    <div className={className}>
      <div className="title">
        <Icon type="warning" color="red" size={40} className="icon" />
        {message}
      </div>
      <button onClick={() => (window.location = window.location)}>
        Reload Page
      </button>
    </div>
  );
  /* eslint-enable no-self-assign */
}

export default styled(Error)`
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  flex-direction: column;
  .icon {
    margin: 10px;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
