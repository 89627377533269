export default function doBuildColumns(
  blocks = [],
  selectBox,
  width,
  maxHeight,
  columnWidth,
  spacing
) {
  const halfspacing = spacing / 2;
  let columnCount = Math.round(width / columnWidth);
  if (columnCount <= 0) columnCount = 1;
  let wantedColumnWidth = (width - (columnCount - 1) * spacing) / columnCount;
  let height = new Array(columnCount);
  let columns = new Array(columnCount);
  let actualHeight = 0;

  // init empty columns
  let i;
  for (i = 0; i < columnCount; i++) {
    height[i] = 0;
    columns[i] = [];
  }

  // fill columns
  for (i = 0; i < blocks.length; i++) {
    // find best column
    let j = 0;
    let h = 0;
    let best = -1;
    for (j = 0; j < columnCount; j++) {
      if (best === -1 || height[j] < h) {
        best = j;
        h = height[j];
      }
    }
    // push image in best column
    let item = blocks[i].item;
    let box = selectBox(item);
    if (!box) continue;
    h = (box.height * wantedColumnWidth) / box.width;
    height[best] += h + 61;
    if (height[best] > actualHeight) actualHeight = height[best];
    if (maxHeight > 0 && actualHeight > maxHeight) {
      //need scrollbar

      return this.doBuildColumns(
        blocks,
        selectBox,
        width, // ie scroll width
        -1,
        wantedColumnWidth,
        halfspacing
      );
    }
    box.width = wantedColumnWidth;
    box.height = h;
    box.marginLeft = best % columnCount === 0 ? 0 : halfspacing;
    box.marginRight = best % columnCount === columnCount - 1 ? 0 : halfspacing;
    box.marginTop = i / columnCount < 1 ? 0 : halfspacing;
    box.marginBottom = halfspacing;

    blocks[i].box = box;
    columns[best].push(blocks[i]);
  }
  for (i = 0; i < columns.length; i++) {
    if (columns[i] && columns[i].length)
      columns[i][columns[i].length - 1].box.marginBottom = 0;
  }

  return columns;
}
