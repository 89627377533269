export default function buildRows(
  items = [],
  selectBox,
  width,
  maxHeight,
  rowHeight,
  spacing = 20,
  direction
) {
  if (items.length === 0) return [];
  let rows = [];
  let deb, fin;
  let actualHeight = 0;
  for (deb = 0; deb < items.length; deb = fin) {
    let rowWidth = 0;
    let i;
    for (i = deb; i < items.length && rowWidth < width; i++) {
      let box = selectBox(items[i].item);

      if (!box) continue;
      let w = box.width;
      let h = box.height;
      let scale = rowHeight / h;
      rowWidth += w * scale;
      items[i].box = box;
    }

    fin = i;
    let count = fin - deb;
    let scale = 1;
    if (rowWidth > width) {
      scale = (width - spacing * count) / (rowWidth - spacing * count);
    }
    actualHeight += rowHeight * scale + 41; // analytic size
    if (maxHeight > 0 && actualHeight > maxHeight) {
      return buildRows(
        items,
        selectBox,
        width,
        -1,
        rowHeight,
        spacing / 2,
        direction
      );
    }
    let row = [];
    for (i = deb; i < fin; i++) {
      let box = items[i].box;
      if (!box) continue;
      let h = box.height;
      let s = rowHeight / h;
      box.width = box.width * scale * s;
      box.height = box.height * scale * s;

      if (i !== deb) items[i].box.marginLeft = spacing;
      if (i !== fin - 1) items[i].box.marginRight = spacing;
      items[i].box.marginTop = spacing;
      items[i].box.marginBottom = spacing;

      row.push(items[i]);
    }
    rows.push(row);
  }
  for (let i = 0; i < rows[0].length; i++) {
    rows[0][i].box.marginTop = 0;
  }
  if (rows && rows.length && rows[rows.length - 1])
    for (let i = 0; i < rows[rows.length - 1].length; i++) {
      rows[rows.length - 1][i].box.marginBottom = 0;
    }
  return rows;
}
