import React from "react";
import styled from "styled-components";
import Icon from "@lectra/icon";
import { useTranslation } from "../../lib/DesignDataExplorer";

function Header({ className, title, logout, version, isVersionVisible }) {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className="title">{title} {isVersionVisible && <span>{version}</span>}</div>
      <div className="menu">
        <ul>
          <li>
            <div className="dropdown">
              <button href="#" title="menu" className="dropbtn">
                <Icon type="menu" color="white" size={null} />
              </button>
              <div className="dropdown-content">
                <button href="#" onClick={logout}>
                  {t("Logout")}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default styled(Header)`
  height: 21pt;
  background-color: #161324;
  padding: 0 0 0 11pt;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: stretch;

  .KUIIcon.icon-menu {
    position: relative;
    top: 6px;
  }
  dropdown {
    overflow: hidden;
  }

  /* Dropdown button */
  .dropdown .dropbtn {
    display: flex;
    border: none;
    outline: none;
    color: white;
    padding: 0px 16px;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
  }

  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 28px;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    background: #161324;
    z-index: 222;
    flex-direction: column;
    align-content: center;
    button {
      border: none;
      padding: 5px;
      margin: 0;
      float: none;
      color: #b3b3b3;
      text-decoration: none;
      display: flex;
      text-align: left;
      background-color: #161324;
    }
  }

  .dropdown-content button:hover {
    color: black;
    background-color: ${(props) => props.theme.app.primary.oppositeColor};
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: flex;
  }

  .title {
    overflow: hidden;
    font-size: 10pt;
    flex: 1;
    margin: 0 0 0 4pt;
    text-transform: uppercase;
    font-weight: bold;
    color: #b3b3b3;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .menu {
    height: 100%;
    background: #f31b44;
    margin: 0;
    padding: 0;
    margin-left: 11pt;

    ul {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: stretch;
      list-style: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }

    li {
      display: flex;
      &:hover,
      &:active {
        color: #f31b44;
        transition: all 0.3s ease;

        .KUIIcon {
          color: #f31b44 !important;
        }
      }

      &:hover {
        background-color: white;
        color: #f31b44 !important;
      }
      &:active {
        color: #f31b44;
        background-color: #d00b30;
      }
    }
  }
`;
