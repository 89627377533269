import React from "react";
import styled from "styled-components";
import LazyImage from "./ImageLoader";


const imageSpacing = 10;
const borderWidth = 1;

function CardImage({
  children,
  uri,
  box = {},
  className,
  alt,
  title,
  selected,
  onClick,
  style,
}) {
  const {
    width,
    height,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
  } = box;
  const imagestyle = {
    // marginTop: imageSpacing,
    // marginBottom: imageSpacing,
    width: width - 2 * borderWidth,
    height: height - 2 * borderWidth,
  };
  return (
    <div
      className={`${className}  ${selected ? "selected" : ""} cardImage`}
      title={title}
      onClick={onClick}
      style={{
        marginLeft,
        marginRight,
        marginBottom,
        marginTop,
        width: width - 2 * borderWidth,
        ...style,
      }}
    >
      <div className="mainboard" style={imagestyle}>
        <LazyImage
          src={uri}
          alt={alt}
          style={{
            height: `calc(100% - ${2 * imageSpacing}px)`,
            width: "auto"
          }}
          loading={() => <div style={imagestyle} />}
          error={() => (
            <div style={imagestyle} className="notfound">
              404
            </div>
          )}
        >
        </LazyImage>
      </div>
      <div className="bandeau">{children}</div>
    </div>
  );
}

export default styled(CardImage)`
  display: flex;
  position: relative;
  cursor: pointer;
  border: ${borderWidth}px solid lightgray;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background: white;

  & > img {
    width: calc(100% - 20px);
    height: auto;
  }
  .notfound {
    font-weight: 600;
    color: #f31b44;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @keyframes animatedBar {
    from {
      width: 0;
    }
    to {
      width: calc(100% + 20px);
    }
  }
  .bandeau {
    display: flex;
    align-self: stretch;
    flex-direction: column;
    padding: 5px 10px;
    font-size: 0.8em;
    border-top: 1px solid
      ${(props) =>
    props.selected ? props.theme.app.primary.oppositeColor : "lightgray"};
    &:before {
      content: " ";
      height: 0px;
      position: relative;
      top: -6px;
      left: 0;
      margin: 0 -10px;
      outline: 1px solid
        ${(props) =>
    props.selected
      ? props.theme.app.primary.color
      : props.theme.app.secondary.color};
      width: 0;
      animation: animatedBar 1s linear infinite;
      ${(props) => (!props.loading ? { display: "none" } : null)}
    }

    div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &.selected {
    .bandeau {
      color: ${(props) => props.theme.app.primary.oppositeColor};
      background-color: ${(props) => props.theme.app.primary.color};
    }
  }
  .mainboard {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .analytics {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
    padding: 3px 10px;
    box-sizing: border-box;
    .analytic {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      flex: 1;
    }
    .span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .selected {
      color: ${(props) => props.theme.app.primary.oppositeColor};
      background-color: ${(props) => props.theme.app.primary.color};
    }
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.app.primary.color};
    box-shadow: 1px 2px 4px rgba(52, 73, 94, 0.9);
  }
`;
