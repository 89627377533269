import React from "react";
import styled from "styled-components";
import buildRows from "./buildRows";
import buildColumns from "./buildColumns";
import useResizeObserver from "../../hooks/useResizeObserver";

const defaultDirection = "column";

function AdaptiveLayout({
  items = [],
  children: renderChild,
  selectBox = (item) => ({
    width: item.images[0].width,
    height: Math.min(item.images[0].height, item.images[0].width),
  }),
  className,
  itemHeight,
  spacing = 20,
  direction = defaultDirection,
}) {
  const [ref, { contentRect }] = useResizeObserver();
  const width = Math.max(contentRect ? contentRect.width : 100, 100);

  const blocks = contentRect
    ? direction === "row"
      ? buildRows(
          items.map((dp) => ({ item: dp, box: null })),
          selectBox,
          width,
          100,
          itemHeight,
          spacing,
          direction
        )
      : buildColumns(
          items.map((dp) => ({ item: dp, box: null })),
          selectBox,
          width,
          -1,
          160,
          spacing
        )
    : [];

  return (
    <div className={`${className} ${direction}`} ref={ref}>
      {blocks.map((block, index) => (
        <div
          className="block"
          key={index}
          style={{ maxWidth: width, overflow: "hidden" }}
        >
          {block.map(({ item, box }, index) => {
            return renderChild(item, box, index);
          })}
        </div>
      ))}
    </div>
  );
}

export default styled(AdaptiveLayout)`
  display: flex;
  overflow: hidden;
  .block {
    display: flex;
  }

  &.column {
    flex-direction: row;
    .block {
      flex-direction: column;
    }
  }
  &.row {
    flex-direction: column;
    .block {
      flex-direction: row;
    }
  }
`;
