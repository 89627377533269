import React, { Component } from "react";
import LectraAuth from "@lectra/auth-js";
import PropTypes from "prop-types";
import AuthenticatingScreen from "../lib/components/authenticatingScreen/authenticatingScreen";

const AuthenticationContext = React.createContext();

const APP_METADATA = "https://metadata.lectra.com/app_metadata";

const wait = (timeout = 0) =>
  new Promise((resolve) => setTimeout(resolve, timeout));

const hasPermissionToLoadApplication = (userPermissions, permission) => {
  if (permission === "") {
    return true;
  }
  return userPermissions.includes(permission);
};

const retrievePermissionsUser = (userInfos = undefined) => {
  if (userInfos && userInfos[APP_METADATA]) {
    return userInfos[APP_METADATA].permissions;
  }
  return null;
};

class AuthenticationProvider extends Component {
  static propsType = {
    SSOHeartBeat: PropTypes.bool,
    auth0: PropTypes.shape({
      authDomain: PropTypes.string.isRequired,
      clientId: PropTypes.string.isRequired,
      baseUrl: PropTypes.string.isRequired,
      callbackUrl: PropTypes.string,
      silentCallbackUrl: PropTypes.string,
      audience: PropTypes.string,
      scopes: PropTypes.string,
    }).isRequired,
    permission: PropTypes.string,
  };
  static defaultProps = {
    SSOHeartBeat: false,
    permission: "",
  };
  state = {
    isAuthenticated: false,
    a: null,
    idToken: null,
    user: null,
  };

  constructor(props) {
    super(props);
    this.Auth = new LectraAuth({
      ...this.props.auth0,
      callbackUrl: "/",
    });
  }

  async componentDidMount() {
    try {
      const response = await this.Auth.checkSSO();

      if (response && response.accessToken && response.idToken) {
        const userInfos = await this.Auth.userInfo(response.accessToken);
        const userPermissions = retrievePermissionsUser(userInfos);
        const hasPermission = hasPermissionToLoadApplication(
          userPermissions,
          this.props.permission
        );

        if (!hasPermission) {
          this.Auth.redirectToLogin();
        } else {
          this.setState(
            {
              isAuthenticated: true,
              accessToken: response.accessToken,
              idToken: response.idToken,
              user: userInfos,
            },
            async () => {
              if (this.props.SSOHeartBeat) {
                await this.ssoLogout();
              }
              // window.history.pushState(
              //   "",
              //   "",
              //   `${window.location.origin}${window.location.pathname}${window.location.search}`
              // );
            }
          );
        }
      } else {
        await this.Auth.redirectToLogin();
      }
    } catch (error) {
      console.error(error);
      await this.Auth.redirectToLogin();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // to avoid re rendering when we logout
    return !(
      this.state.isAuthenticated === true && nextState.isAuthenticated === false
    );
  }

  logout() {
    this.setState(
      {
        isAuthenticated: false,
      },
      () => this.Auth.logout()
    );
  }

  async ssoLogout(interval = 5000) {
    while (true) {
      await wait(interval);
      try {
        const sso = await this.Auth.IsSSOActive();
        if (!sso) {
          await this.logout();
        }
      } catch (error) {
        console.log(error);
        // We are probably offline... Do nothing...
      }
    }
  }

  render() {
    // this.logout()
    return (
      <AuthenticationContext.Provider
        value={{
          ...this.state,
          actions: {
            logout: this.logout.bind(this),
          },
        }}
      >
        {this.state.isAuthenticated ? (
          this.props.children
        ) : (
          <AuthenticatingScreen></AuthenticatingScreen>
        )}
      </AuthenticationContext.Provider>
    );
  }
}

const AuthenticationConsumer = AuthenticationContext.Consumer;

export default AuthenticationProvider;
export { AuthenticationConsumer, AuthenticationContext };
