import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import fr_FR_translation from "../../locales/design-data-explorer_fr-FR.json";
import en_US_translation from "../../locales/design-data-explorer_en-US.json";
import de_DE_translation from "../../locales/design-data-explorer_de-DE.json";
import es_ES_translation from "../../locales/design-data-explorer_es-ES.json";
import it_IT_translation from "../../locales/design-data-explorer_it-IT.json";
import ja_JP_translation from "../../locales/design-data-explorer_ja-JP.json";
import ko_KR_translation from "../../locales/design-data-explorer_ko-KR.json";
import pl_PL_translation from "../../locales/design-data-explorer_pl-PL.json";
import pt_PT_translation from "../../locales/design-data-explorer_pt-PT.json";
import zh_CN_translation from "../../locales/design-data-explorer_zh-CN.json";

export{ useTranslation } from "react-i18next";
export const initLanguage = ()=> {
// the translations
// (tip move them in a JSON file and import them)
  let resources = {};
  let fallbackLng = ["en"];
  let availableLanguages = [];

  function addLanguage(...trads) {
    trads.forEach((trad) => {
      const name = trad["@@_ISO"].substring(0, 2);
      resources[name] = {
        translation: trad,
      };
      availableLanguages.push(name);
    });
  }

  addLanguage(
    fr_FR_translation,
    en_US_translation,
    de_DE_translation,
    es_ES_translation,
    it_IT_translation,
    ja_JP_translation,
    ko_KR_translation,
    pl_PL_translation,
    pt_PT_translation,
    zh_CN_translation
  );

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
      resources,
      fallbackLng,
      initImmediate: false,
      whitelist: availableLanguages,
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });

}

export default i18n;
