import all from "../../package.json";
import React, { useContext } from "react";
import styled from "styled-components";
import { useQueryParams } from "raviger";
import { AuthenticationContext } from "./authentication.context";
import DesignDataExplorer , { initLanguage, useTranslation } from "../lib/DesignDataExplorer";
import Header from "../components/header/header";

initLanguage();

function App({ className }) {
  const { accessToken, actions } = useContext(AuthenticationContext);
  const [queryParams, setQueryParams] = useQueryParams();
  const { t } = useTranslation();

  const onSearch = (searchTerm, orderBy, sort) =>
    setQueryParams({ orderBy: orderBy, sort: sort, search: searchTerm });

  return (
    <div className={className}>
      <Header title={t("DESIGN Explorer")} logout={actions.logout} version={all.version} isVersionVisible={false}/>
      <DesignDataExplorer
        baseUrlApi={process.env.REACT_APP_DESIGNDATA_API_BASE_URL}
        accessToken={accessToken}
        onSearch={onSearch}
        searchTerm={queryParams.search}
        orderBy={queryParams.orderBy}
        sort={queryParams.sort}
      />
    </div>
  );
}

export default styled(App)`
  ${DesignDataExplorer} {
    height: calc(100% - 28px) !important;
  }
`;
