export default function findBestImage(designpack, { width = 0, height = 0 }) {

  if (!designpack || designpack.length === 0)
    return "";
  if (designpack.length === 1)
    return designpack[0].uri;
  const len = designpack ? designpack.length : 0;
  let i, bestRatio = 0;
  let best = null;
  for (i = 0; i < len; i++) {
    const v = designpack[i];
    let rw = v.width < width ? v.width/width : width/v.width;
    let rh = v.height < height ? v.height/height : height/v.height;
    if(!rw)
      rw = 1; // don't care
    if(!rh)
      rh = 1;
    let r = Math.min(rw,rh);
    if(r > bestRatio) {
      bestRatio = r;
      best = v;
    }
  }
  return best.uri;
}
