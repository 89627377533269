import React, { Fragment } from "react";
import styled, { withTheme } from "styled-components";
import Icon from "@lectra/icon";
import { useTranslation } from "react-i18next";

import CardImage from "../cardImage/cardImage";
import { mainboardSymbol } from "../../hooks/useDesignData";
import useGetScrollBarWidth from "../../hooks/useGetScrollBarWidth";
import AdaptiveLayout from "../adaptiveLayout/adaptiveLayout";
import { formatTime, formatTimeToMinute } from "../../utils/time";
import findBestImage from "../../utils/findBestImage";

function stringAContainsB_Insensitive(str1 = "", str2 = "") {
  return str1.toLowerCase().indexOf(str2.toLowerCase()) >= 0;
}

function Details(props) {
  const {
    theme,
    item,
    className,
    searchString,
    searchDesignPacks,
    selectedViewports = [],
    toggleViewportSelection = () => null,
    deleteDesignPack,
    enableOpenInKaledo,
    enableDownload,
    enableDelete,
    selectText,
    onSelect,
  } = props;
  const { ref, scrollWidth } = useGetScrollBarWidth();
  const { t } = useTranslation();

  if (item === null) return <Fragment />;
  if (item.status === "deleted")
    return <div className={`${className} deleted`}>deleted</div>;
  const { viewPorts = [], metadata = {}, mainBoardImages = [] } = item;
  const { fileName, author, storedDate, tags = [] } = metadata;

  return (
    <div className={className}>
      <div className="content" ref={ref}>
        <div className="analytics">
          <span className="fileName" title={fileName}>
            {fileName}
          </span>
          <span
            className="author"
            onClick={() => searchDesignPacks({ searchString: author, skip: 0 })}
            title={author}
          >
            {author}
          </span>
          <span title={formatTime(storedDate)}>
            {formatTimeToMinute(storedDate)}
          </span>
          <div className="tags">
            {tags.map((tag) => {
              const isMatchingTag =
                searchString === ""
                  ? false
                  : searchString.split(" ").reduce((accu, item) => {
                      if (stringAContainsB_Insensitive(tag, item)) return true;
                      return accu;
                    }, false);

              return (
                <span
                  key={tag}
                  className={`tag ${isMatchingTag ? "match" : ""}`}
                  onClick={() =>
                    searchDesignPacks({ searchString: tag, skip: 0 })
                  }
                  title={tag}
                >
                  {tag}
                </span>
              );
            })}
          </div>
        </div>
        <div className="mainBoard" title="mainboard">
          <CardImage
            uri={findBestImage(mainBoardImages, {
              width: theme.app.detailCardWidth - scrollWidth - 32,
            })}
            selected={selectedViewports.indexOf(mainboardSymbol) !== -1}
            title={
              fileName +
              "\n" +
              `${t("available sizes")} [${mainBoardImages
                .map((i) => i.width + "x" + i.height)
                .join(", ")}]`
            }
            onClick={() => toggleViewportSelection(mainboardSymbol)}
            box={{
              marginLeft: 10,
              marginRight: 10,
              marginBottom: 10,
              marginTop: 10,
              width: theme.app.detailCardWidth - scrollWidth - 32,
              height: Math.min(
                (mainBoardImages[0].height *
                  (theme.app.detailCardWidth - scrollWidth - 32)) /
                  mainBoardImages[0].width,
                theme.app.detailCardWidth - scrollWidth - 32
              ),
            }}
          >
            <span className="bold">{t("Mainboard")}</span>
          </CardImage>
        </div>
        <div className="viewports">
          <AdaptiveLayout items={viewPorts} spacing={10}>
            {(viewport, box) => {
              return (
                <CardImage
                  key={viewport.name}
                  onClick={() => toggleViewportSelection(viewport.name)}
                  selected={selectedViewports.indexOf(viewport.name) !== -1}
                  uri={findBestImage(viewport.images, { width: box.width-22})}
                  title={
                    viewport.name +
                    "\n" +
                    (viewport.comment ? viewport.comment + "\n" : "") +
                    `${t("available sizes")}: [${viewport.images
                      .map((i) => i.width + "x" + i.height)
                      .join(", ")}]`
                  }
                  box={box}
                >
                  <div className="bottom">
                    <div>{viewport.name}</div>
                    {viewport.comment && (
                      <Icon type="comment" className="comment"></Icon>
                    )}
                  </div>
                </CardImage>
              );
            }}
          </AdaptiveLayout>
        </div>
      </div>

      <div className="select-bar">
        {enableDelete && (
          <button
            onClick={() => {
              if (
                window.confirm(
                  t("Do you really want to delete this element?")
                )
              )
                deleteDesignPack(item);
            }}
          >
            {t("delete")} <Icon type="delete" size="12pt"></Icon>
          </button>
        )}
        {enableDownload && (
          <a href={item.metadata.binaryUri}>
            {t("download")} <Icon type="download" size="12pt"></Icon>
          </a>
        )}
        {enableOpenInKaledo && (
          <a
            href={`lectra://kaledostyle/open?ident=${item.metadata.ident}&sha256=${item.metadata.sha256}`}
          >
            {t("open")} <Icon type="cloud-file" size="12pt"></Icon>
          </a>
        )}
        {onSelect != null && (
          <button
            disabled={
              selectedViewports === null || selectedViewports.length === 0
            }
            onClick={() => {
              var i = selectedViewports.indexOf(mainboardSymbol);
              if (onSelect)
                onSelect({
                  selectedDesignPack: item,
                  selectedViewPorts:
                    i !== -1
                      ? [].concat(
                          selectedViewports.slice(0, i),
                          selectedViewports.slice(i + 1)
                        )
                      : selectedViewports,
                  mainBoardImagesIsSelected: i !== -1,
                });
            }}
          >
            {selectText}
            <Icon type="link" size="12pt"></Icon>
          </button>
        )}
      </div>
    </div>
  );
}

export default styled(withTheme(Details))`
  display: flex;
  flex-direction: column;
  height: 100%;
  .content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow-y: auto;
    //overflow-y: overlay;
    background: #f2f2f2;
    border: 1px solid lightgray;
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #666;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
      background: #ddd;
      border-radius: 20px;
    }
    .bold {
      font-weight: 600;
      font-size: larger;
    }
    .openInKaledo {
      background: white;
      margin: 5px 20px 0 10px;
      padding: 6px 6px;
      border: 1px solid lightgray;
      display: flex;
      text-decoration: none;
      color: black;
      justify-content: center;
      .text {
        margin-right: 10px;
      }
      &:hover {
        border: 1px solid
          ${(props) =>
            props.onSelect != null
              ? props.theme.app.primary.color
              : "lightgray"};
        box-shadow: 1px 2px 4px rgba(52, 73, 94, 0.9);
      }
    }

    ${CardImage} {
      background: white;
      &.selected::before {
        content: ".";
        width: 20px;
        height: 20px;
        background: ${(props) => props.theme.app.primary.color};
        overflow: hidden;
        position: absolute;
        right: -10px;
        top: -10px;
        transform: rotate(45deg);
      }

      .bottom {
        display: flex;
        flex: 1 1 auto;
        overflow: hidden;
        flex-direction: row;
        .comment {
          flex: auto 0 0;
          margin-left: 3px;
        }
        & > div:first-child {
          flex: 1 1 auto;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    .selected .icon-comment {
      color: ${(props) => props.theme.app.primary.oppositeColor};
    }
    .mainBoard {
      display: flex;
      flex-direction: column;
    }

    .analytics {
      margin: 0 10px;
      display: flex;
      flex-direction: column;

      & > span {
        line-height: 1.5em;
        flex-direction: column;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .fileName {
        margin-top: 10px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1 1 auto;
      }
      .author {
        cursor: pointer;
      }
      .tags {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .tag {
        font-size: small;
        margin-right: 6px;
        cursor: pointer;
        margin-bottom: 6px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.match {
          font-weight: 600;
          background-color: ${(props) => props.theme.app.primary.color};
          color: ${(props) => props.theme.app.primary.oppositeColor};
          display: inline-block;
          padding-left: 8px;
          padding-right: 8px;
          padding-bottom: 4px;
          padding-top: 1px;
          text-align: center;
        }
      }
    }
    .viewports {
      margin: 0 10px 10px 10px;
    }

    .cardImage:hover {
      ${({ onSelect }) =>
        onSelect == null &&
        `
    border-color: lightgray!important;
     box-shadow: none;
     cursor: unset;
  `}
    }
  }
  .select-bar {
    background: ${(props) => props.theme.app.primary.color};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0;
    a,
    button {
      .KUIIcon {
        color: ${(props) => props.theme.app.primary.color};
        position: relative;
        top: 0px;
        left: 4px;
      }
      margin-right: 5px;
      appearance: none;
      cursor: pointer;
      font-size: 12px;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      padding: 0.35em 1.2em;
      border: 0.1em solid ${(props) => props.theme.app.primary.color};
      border-radius: 0.12em;
      box-sizing: border-box;
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      color: ${(props) => props.theme.app.primary.color};
      text-align: center;
      transition: all 0.2s;
      color: #000000;
      background-color: #ececec;
      outline: transparent;
      border: 1px solid #fbfbfb;
      &:hover {
        color: #000000;
        background-color: white;
        border: 1px solid #fbfbfb;
        outline: white;
      }
      &:focus {
        outline: white;
      }
      &:disabled,
      &[disabled] {
        cursor: default;
        border: 1px solid #999999;
        background-color: #ececec;
      }
      &:active {
        color: black;
        background: white;
        border: 1px solid white;
        outline: 1px solid white;
      }
    }
  }
`;
