import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

function AuthenticatingScreen({ className }) {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <div className="title">
        <div className="lectra-quality-bar" />
        <span>{t("DESIGN Explorer")}</span>
      </div>
      <div>{t("Authenticating")}...</div>
    </div>
  );
}

export default styled(AuthenticatingScreen)`
  background: white;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  flex-direction: column;
  color: #b3b3b3;
  .icon {
    margin: 10px;
  }
  .lectra-quality-bar {
    height: 6px;
    width: 25px;
    position: relative;
    bottom: -9px;
    left: 21px;
    background: #f31b44;
  }
  .title {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      z-index: 1;
      font-weight: 700;
    }
  }
`;
