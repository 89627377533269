import React from "react";
import styled, { withTheme } from "styled-components";
import { useTranslation } from "react-i18next";

import SearchBar from "./components/searchBar/searchBar";
import Details from "./components/detail/details";
import AdaptiveLayout from "./components/adaptiveLayout/adaptiveLayout";

import CardImage from "./components/cardImage/cardImage";
import DesignPackCardImage from "./components/cardImage/designPackCardImage";
import Error from "./components/error/error";
import useDesignData from "./hooks/useDesignData";
import Icon from "@lectra/icon";

export * from "./i18n/index";

export { default as AuthenticatingScreen } from "./components/authenticatingScreen/authenticatingScreen";

export const themeDefault = {
  app: {
    primary: { color: "#34495e", oppositeColor: "white" },
    secondary: { color: "#2980b9", oppositeColor: "white" },
    danger: { color: "#f31b44" },
    detailCardWidth: 380,
    resultImageHeight: 200,
    resultImageSpacing: 10,
    windowMode: false,
  },
};

const addFakeItemLoader = (designPackList, designPackCount) => {
  if (designPackCount === designPackList.length || designPackList.length === 0)
    return designPackList;
  return designPackList.concat({
    isLoader: true,
    mainBoardImages: [{ width: 200, height: 200 }],
  });
};

function DesignDataExplorer(props) {
  const scrollRef = React.useRef(null);
  const { t } = useTranslation();

  const {
    className,
    toggleViewportSelection,
    deleteDesignPack,
    searchDesignPacks,
    onCloseDetail,
    selectDesignPack,
    theme,
    titleText = t("Search"),
    onSelect,
    selectText = t("Select"),
    enableOpenInKaledo = true,
    enableDownload = true,
    enableDelete = true,
    toggleSort,
    toggleOrderBy,
    selection,
    designPackCount,
    designPackList,
    request,
    selectedViewports,
    loadings,
    error,
  } = useDesignData({ ...props, scrollRef });
  return (
    <div className={className}>
      <div className="stikyHeader">
        <SearchBar
          loading={loadings.search > 0}
          className="search-bar"
          onSet={searchDesignPacks}
          searchString={request.searchString}
          designPackCount={designPackCount}
          request={request}
          onCloseDetail={onCloseDetail}
          titleText={titleText}
          toggleSort={toggleSort}
          toggleOrderBy={toggleOrderBy}
          enableOpenInKaledo={enableOpenInKaledo}
        />
      </div>
      <div className="body" ref={scrollRef}>
        {error && <Error exception={error}></Error>}
        <AdaptiveLayout
          direction="row"
          items={addFakeItemLoader(designPackList, designPackCount)}
          itemHeight={theme.app.resultImageHeight}
          spacing={theme.app.resultImageSpacing}
          selectBox={(designpack) => ({
            width: designpack.mainBoardImages[0].width,
            height: Math.min(
              designpack.mainBoardImages[0].height,
              designpack.mainBoardImages[0].width
            ),
          })}
        >
          {(designpack, box, index) =>
            designpack.isLoader ? (
              <div style={{ ...box }} className="fakeItemLoader" key={index}>
                <div>
                  <Icon type="in-process" color="#2980b9" size={30} />
                </div>
              </div>
            ) : (
              <DesignPackCardImage
                key={designpack.documentId}
                designpack={designpack}
                box={box}
                loading={loadings.detail[designpack.documentId]}
                onClick={() => selectDesignPack(designpack)}
                selected={
                  selection && selection.documentId === designpack.documentId
                }
              />
            )
          }
        </AdaptiveLayout>
      </div>
      {selection && (
        <div className="detailHolder">
          <Details
            className="detail"
            toggleViewportSelection={toggleViewportSelection}
            searchDesignPacks={searchDesignPacks}
            searchString={request.searchString}
            width={300}
            item={selection}
            onSelect={onSelect}
            deleteDesignPack={deleteDesignPack}
            enableOpenInKaledo={enableOpenInKaledo}
            enableDownload={enableDownload}
            enableDelete={enableDelete}
            selectText={selectText}
            selectedViewports={selectedViewports}
          />
        </div>
      )}
    </div>
  );
}

export default styled(withTheme(DesignDataExplorer))`
  position: absolute;
  overflow: hidden;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: auto;
  grid-template-rows: 80px 1fr;
  gap: 0 0px;
  grid-template-areas:
    "stikyHeader"
    "body";

  .stikyHeader {
    grid-area: stikyHeader;
    position: relative;
    top: 0;
    z-index: 1;
    height: 0;
    .search-bar {
      position: relative;
      top: 0;
      width: 100%;
    }
  }
  .detail {
    padding-right: 10px;
  }
  .detailHolder {
    overflow: hidden;
    position: absolute;
    width: ${(props) => props.theme.app.detailCardWidth}px;
    top: 80px;
    right: 10px;
    height: calc(100% - 100px);
    padding: 10px;
  }
  .fakeItemLoader {
    display: flex;
    & > div {
      display: flex;
      margin: 10px;
      flex: 1 1 auto;
      background-image: linear-gradient(
        90deg,
        #e4e4e4 0%,
        #f1f1f1 40%,
        #ededed 60%,
        #e4e4e4 100%
      );
      background-repeat: repeat;
      animation: animatedBackground 5s linear infinite;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
  .body {
    grid-area: body;
    overflow-y: scroll;
    // padding:10px ${(props) => props.theme.app.detailCardWidth}px 10px 10px;
    padding-top: 10px;
    padding-right: ${(props) => props.theme.app.detailCardWidth + 10}px;
    margin: 0px 0px 0px 10px;

    ${CardImage} {
      border-color: transparent;
      &:hover {
        border-color: ${(props) => props.theme.app.primary.color};
      }
    }
  }
`;
